.IconDimen {
  width: 24px;
  height: 24px;
}
.linkCustom {
  color: var(--ddl-color--primary-emerson-green-dark);
  cursor: pointer;
  text-decoration: underline;
}
.commonDisplaysize {
  max-width: 600px;
}
.endUserInfoDisplaySize{
  max-width: 790px;
}
.redError {
  color: red;
  text-align: center;
}

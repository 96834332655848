.userContainer {
  margin-top: 40px;
}
.userContainer .loginSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
  margin: 55px auto 0px;
}
.userContainer .loginSection .normalLogin,
.userContainer .loginSection .guestLogin {
  width: 30%;
}
.userContainer .loginSection .verticalBorder {
  border-right: 1px solid #c9c9c9;
}
.userContainer .loginSection .normalLogin .ddl-text--h4 {
  margin-bottom: 30px;
}
.userContainer .loginSection .normalLogin div:last-child {
  margin-top: 25px !important;
}
.userContainer .loginSection .guestLogin .ddl-button-cta--tertiary {
  margin-top: 30px;
}
.userContainer .loginSection .guestLogin p {
  margin-top: 25px;
}
.userContainer .btnGrpSignIn {
  display: flex;
  flex-direction: column;
}
.userContainer .btnGrpSignIn .ddl-button-cta {
  margin: 13px;
}

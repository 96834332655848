.materialSysDoc {
  padding-top: 30px;
}
.materialSysDoc p span {
  margin: 25px 0px;
}
.materialSysDoc span p {
  margin: 0 5px;
}
.materialSysDoc .ddl-text--h6 {
  font-weight: 800;
}
.materialSysDoc .ContentDiv {
  margin: 40px 0px;
}
.materialSysDoc .buttonNext {
  margin-top: 35px;
}
.materialSysDoc .dropzone .dropContainer aside p {
  display: flex;
  align-items: center;
}
.materialSysDoc .dropzone .dropContainer aside p span {
  margin-left: 10px;
}
.materialSysDoc .dropzone .dropContainer .dropzoneBox {
  border: 1px dashed #c4c4c4;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
}
.materialSysDoc .dropzone .dropzoneBtn i {
  display: none;
}
.materialSysDoc .errorText {
  color: red;
}
.materialSysDoc .dropContainer p {
  margin: 0px 0px 13px 0px;
}

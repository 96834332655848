.deviceServiceRequest .css-dmmspl-MuiFormGroup-root {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 171px;
}

.deviceServiceRequest .SiteConReq {
  display: flex;
}

.deviceServiceRequest .SiteConReq .ddl-from-custom-container:first-child {
  margin-right: 35px;
}
.errorText {
  color: red;
}

@media screen and (max-width: 768px) {
  .deviceServiceRequest .SiteConReq {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 20px;
    padding-bottom: 20px;
  }
}

.SubmitRequestFinal .SubmitContent .ddl-text--h3 {
  margin: 20px 0px 20px 0px;
}
.SubmitRequestFinal .submitOptions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin: 50px 0px 20px 0px;
}
.SubmitRequestFinal .submitOptions img {
  margin-bottom: 20px;
}
.SubmitRequestFinal .submitOptions .ValueOptions .ddl-text--h5 {
  margin-bottom: 20px;
}
.SubmitRequestFinal .ddl-button-cta {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .SubmitRequestFinal .submitOptions {
    display: block;
  }

  .SubmitRequestFinal .submitOptions .pinIcon,
  .supportMan1 {
    display: block;
    margin-top: 20px;
  }
}
